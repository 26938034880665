<template>
<div class="survey-section">
    <div class="clamped">
        <h4 id="more" class="section-heading">{{ $t('SURVEY_DETAILS.GAME_MODES') }}</h4>
        <section class="survey-scramble">
            <div
                v-for="mode in modes"
                :key="mode.key"
                class="padded mode-container"
                :class="windowWidth < 600 ? 'flex-column' : 'flex-row'"
            >
                <div class="mode-info">
                    <p class="name">
                        <img :src="`/images/survey/mode-${mode.key}.png`" :alt="mode.name">
                        {{ mode.name }}
                    </p>
                    <p class="description">{{ mode.description }}</p>
                    <p v-if="mode.isTeam" class="team">
                        {{ $t('SURVEY_DETAILS.TEAM_GAME') }}
                        <img :src="`/images/survey/team-${mode.key}.png`" :alt="mode.name">
                    </p>
                </div>
                <div class="mode-media">
                    <img :src="`/images/survey/mode-${mode.key}.jpg`" :alt="mode.name">
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        windowWidth: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            modes: [
                {
                    key: 'hilo',
                    name: this.$t('SURVEY_DETAILS.HILO_NAME'),
                    description: this.$t('SURVEY_DETAILS.HILO_DESCRIPTION')
                },
                {
                    key: 'bounce',
                    name: this.$t('SURVEY_DETAILS.BOUNCE_NAME'),
                    isTeam: true,
                    description: this.$t('SURVEY_DETAILS.BOUNCE_DESCRIPTION')
                },
                {
                    key: 'squares',
                    name: this.$t('SURVEY_DETAILS.SQUARES_NAME'),
                    isTeam: true,
                    description: this.$t('SURVEY_DETAILS.SQUARES_DESCRIPTION')
                },
                {
                    key: 'speed',
                    name: this.$t('SURVEY_DETAILS.SPEED_NAME'),
                    description: this.$t('SURVEY_DETAILS.SPEED_DESCRIPTION')
                }
            ]
        }
    }
})

</script>

<style lang="scss" scoped>
@import "../styles/kit.scss";

.clamped {
    width: 90%
}

.mode-container {
    display: flex;
    width: 100%;
    gap: 50px;
    margin: 20px auto 80px;
}

.mode-info {
    flex-basis: 50%;
    align-content: center;
    text-align: right;

    .name {
        position: relative;
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 0.5rem;

        img {
            height: 36px;
            margin: 0 5px;
        }
    }

    .description {
        font-size: 16px;
        line-height: 1.3;
    }

    .team {
        font-weight: 900;

        img {
            display: inline;
            height: 32px;
            margin: 0 5px 3px;
            vertical-align: middle;
        }
    }
}

.mode-media {
    flex-basis: 50%;

    img {
        width: 100%;
        border-radius: 25px;
        box-shadow: -4px -4px 64px 48px rgba(255, 255, 255, 0.04);
    }
}

.flex-row:nth-child(even) {
    flex-direction: row-reverse;

    .mode-info {
        text-align: left;
    }
}

.flex-column {
    flex-direction: column-reverse;
    gap: 0px;
    margin: 10px auto 40px;

    .mode-info {
        text-align: left;

        .name {
            font-size: 32px;

            img {
                height: 28px;
                margin: 0 3px;
            }
        }
    }

    .mode-media {
        img {
            width: calc(100% + 32px);
            margin: 0 -16px 10px;
            border-radius: 0px;
        }
    }
}

@include mq-medium {
    .mode-info {
        .mode-name {
            font-size: 38px;

            img {
                height: 28px;
            }
        }

        .mode-description{
            font-size: 14px;
        }
    }

    .mode-media img {
        width: 100%;
    }
}

@include mq-small {
    .mode-media img {
        min-height: 250px;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}

@include mq-xsmall {
    .clamped {
        width: 100%
    }
}
</style>
